<template>
  <dashboard-layout>
    <div class="w-full">
      <div class="px-4 flex">
        <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
          <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Minhas Notificações</h1>
          <div class="h-1 w-1/5 bg-green-600 rounded"></div>
        </div>
      </div>
      <div class="p-4">
        <div class="flex rounded-lg h-full p-3 flex-col">
          <div v-for="(notification, index) in notification" :key="notification.id">
            <div
              class="flex items-center px-4 py-3 mb-2 transition-colors duration-300 transform border-b hover:bg-gray-300 dark:hover:bg-gray-700 dark:border-gray-700 rounded-lg border-solid border-l-8"
              :class="{ 'bg-white border-green-600': notification.visualized === 0 }">
              <div class="flex w-full justify-between">
                <button class="flex flex-col items-start w-full ml-2"
                  @click="readnotification(notification.id, notification.url, index)">
                  <p class="font-bold text-start text-base text-gray-600">{{ notification.title }}</p>
                  <p class="text-sm text-start text-gray-600 dark:text-white">{{ notification.description }}</p>
                  <p class="text-sm text-gray-600 dark:text-white">{{ notification.tempo }}.</p>
                </button>
                <button v-if="notification.visualized === 0" class="text-blue-500 hover:underline w-36"
                  @click="buttonviewed(notification.id, index)">
                  Marcar como lida
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';

export default {
  name: 'notification',

  title() {
    return `${process.env.VUE_APP_NAME} | Associados`;
  },

  components: {
    DashboardLayout
  },


  data() {
    return {
      notification: []
    };
  },

  methods: {

    readnotification(id, url, index) {
      if (url) {
        window.open(url, "_blank");
      }

      axios
        .post('/api/notificacao-view', {
          notification_id: id
        });
      this.counternot = this.counternot - 1;
      this.notification[index].visualized = 1;
    },

    buttonviewed(id, index) {
      axios
        .post('/api/notificacao-view', {
          notification_id: id
        });
      this.counternot = this.counternot - 1;
      this.notification[index].visualized = 1;
    },

    async fetchNotification() {
      await axios.get('/api/notificacao').then(({ data }) => {
        data.data.forEach(not => {
          this.notification.push({
            id: not.id,
            title: not.title,
            description: not.description,
            tempo: not.tempo,
            visualized: not.visualized,
            url: not.url
          });
        });
      });
    }
  },
  created: function () {
    this.fetchNotification();
  }
};
</script>

<style scoped>

</style>
