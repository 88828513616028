<template>
  <dashboard-layout v-slot="userAccess">
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div v-if="userAccess.userAccess.access.isRevoke" class="text-red-400 font-bold w-full text-center mb-5">
        <p>
          Seu acesso foi expirado em
          {{ userAccess.userAccess.access.revoke_access_at }}.
        </p>
        <p>
          Entre em contato com o seu sindicato para liberação do seu acesso
          definitivo.
        </p>
        <p>
          Sindicato: {{ userAccess.userAccess.informations.syndicate.name }}.
          Telefone:
          {{
              userAccess.userAccess.informations.syndicate.telephone
              | VMask('(##) ####-####')
          }}
        </p>
      </div>

      <modal-layout>
        <template v-slot:header>
          <h1>Atenção</h1>
        </template>
        <template v-slot:body>
          <p>
            Seu acesso foi expirado! Por favor, entre em contato com seu
            sindicato para solicitar renovação do seu acesso!
            <br /><br />
            Sindicato: {{ userAccess.userAccess.informations.syndicate.name }}.
            <br />
            Telefone:
            {{
                userAccess.userAccess.informations.syndicate.telephone
                | VMask('(##) ####-####')
            }}
          </p>
        </template>
      </modal-layout>

      <div class="p-4">
        <div class="lg:w-1/2 w-full mb-10">
          <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Talentos Favoritos</h1>
          <div class="h-1 w-1/5 bg-green-600 rounded"></div>
        </div>
        <span class="text-red-600 flex w-full flex-row-reverse">*Para remover um talento da lista de favoritos, entre no curriculo e desmarque a estrelinha de favoritos.</span>
        <v-client-table ref="table" :data="favorites" :columns="columns" :options="options">
          <div class="flex justify-end" slot="currículo" slot-scope="{ row }">
            <router-link
                class="flex p-1 border-2 w-max rounded-lg mr-4 border-yellow-500 hover:bg-yellow-500 text-yellow-500 focus:outline-none focus:shadow-outline has-tooltip"
                :to="{ name: 'curriculum', params: { id: row.participant_id } }"
                v-tooltip="{ content: 'Ver Currículo', placement: 'left' }"
                target="_blank"
              >
                <i
                  class="ri-article-line py-0 px-2 text-2xl hover:text-green-50"
                ></i>
              </router-link>
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';
import ModalLayout from '../../../components/Modal';

export default {
  name: 'Index',

  title() {
    return `${process.env.VUE_APP_NAME} | Talentos Favoritos`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation,
    ModalLayout
  },

  data() {
    return {
      isLoading: true,
      favorites: [],
      columns: [
        'id',
        'participant_id',
        'nome',
        'email',
        'city',
        'currículo'
      ],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          email: 'E-mail',
          city: 'município'
        },
        hiddenColumns: ['id', 'participant_id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} Talentos|{count} Talentos|Uma Talento',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum talento favoritado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },

  methods: {
    fetchFavorites() {
      axios.get('/api/associados/favorites').then(({ data }) => {
        data.data.forEach(favorite => {
          this.favorites.push(favorite);
        });
      });
      return Promise.resolve(this.isLoading = false);
    }

  },

  created() {
    this.fetchFavorites();
  }
};
</script>

<style scoped>

</style>
